<template>
  <div class="job_detail train_detail" v-loading="loading" element-loading-text="拼命加载中">
    <div class="header">
      <div class="header_content">
        <div class="header_bottom flex">
          <img :src="detail.logo" alt="" class="logo">
          <div class="info">
            <h1>{{ detail.name }}</h1>
            <div class="down">
              <div class="times" v-if="active == '盲按'">
                <p>地址：{{detail.addrProvince+detail.addrCity+detail.addrThreeCity+detail.addrDetail}}</p>
                <p>人员：{{detail.nums}}人</p>
                <p>床位：{{ detail.beds }}个</p>
              </div>
              <div class="times" v-if="active == '文化'">
                <p>概述：{{detail.summary}}</p>
              </div>
              <div class="times" v-if="active == '孵化'">
                <p>地址：{{detail.addrProvince+detail.addrCity+detail.addrThreeCity+detail.addrDetail}}</p>
                <p>面积：{{detail.area}}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content flex">
      <div class="content_left">
        <div class="title">
          <h2>{{ title }}</h2>
        </div>
        <div class="text" v-html="detail.detail">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "trainDetail",
  data() {
    return {
      detail: [],
      shareUrl:'',
      active:'',
      title:'',
    }
  },
  methods: {
    async info() {
      this.loading = true;
      try {
        let url = '';
        if (this.active == '盲按'){
          this.title = '盲按店详情';
          url = '/api/app-jycy-henan/henan/bindshop/detail/';
        }else if (this.active == '文化'){
          this.title = '非物质文化遗产详情';
          url = '/api/app-jycy-henan/henan/nonGenetic/detail/';
        }else if (this.active == '孵化'){
          url = '/api/app-jycy-henan/henan/incubation/detail/';
          this.title = '就业孵化基地展示';
        }
        let res = await this.$axios.get(url+this.$route.query.id);
        this.detail = res.data.data;
        console.log(this.detail)
        this.loading = false;

      } catch (e) {
        this.loading = false;
      }
    },
  },
  created() {
    this.active = this.$route.query.name;
    this.info();
  },
  mounted() {
  }
}
</script>

<style scoped lang="less">
@import "~assets/css/job.less";
@import "~assets/css/train.less";
.train_detail{
  padding-top: 10px;
  .header{
    padding-top: 20px;
    .header_bottom{
      .logo{
        width: 208px;
        height: 156px;
        margin-top: 5px;
      }
      .info{
        margin-left: 20px;
      }
      .down{
        .times{
          color: #444D45;
        }
      }
    }
    .buttons{
      position: relative;
      .err_tip{
        position: absolute;
        top: 55px;
        right: 30%;
        transform: translateX(30%);
        color: #ff0000;
      }
    }
  }
  .content .content_left{
    width: 100%;
  }
}
</style>
